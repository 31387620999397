.participle-table {
  text-align: center;
  flex: 1;
  overflow: auto;

  button {
    margin-top: 24px;
    margin-bottom: 64px;
    padding-right: 48px;
    background-image: url('../assets/icon-arrow.svg');
    background-position: 90% 50%;
    background-repeat: no-repeat;
  }

  h1 {
    margin: 64px 0;
    margin-bottom: 44px;
    font-size: 36px;
    color: #fff;
  }

  .tables {
    display: flex;
    max-width: 990px;
    margin: 20px auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    margin: 30px 20px;
    max-width: calc(50% - 40px);
    text-align: left;

    thead {
      tr {
        th {
          font-size: 17px;
          text-align: left;
          color: #fff;
          padding-bottom: 10px;
          font-weight: normal;
          font-family: 'local-husans', sans-serif;

          &:first-child {
            font-size: 28px;
          }

          &:nth-child(2) {
            color: #25BB47;
          }

          &:nth-child(3) {
            color: #0874D9;
          }
        }
      }
    }

    tbody {
      td {
        padding: 8px 12px;
        line-height: 30px;
        background: #fff;
        color: #96A5A5;
        font-size: 16px;
        font-weight: normal;
        border-bottom: 2px solid #BFC4C8;
        border-right: 1px solid #BFC4C8;
        white-space: nowrap;

        &:first-child {
          border-left: 2px solid #BFC4C8;
        }

        &:last-child {
          border-right: 2px solid #BFC4C8;
        }

        input {
          display: block;
          width: calc(100% + 24px);
          margin: -8px -12px;
          border: 0;
          -webkit-appearance: none;
          outline: none;
          font-size: 16px;
          height: 46px;
          background: #fff;
          padding: 0 12px;
          box-sizing: border-box;
        }
      }

      tr:first-child td {
        border-top: 2px solid #BFC4C8;

        &:first-child {
          border-top-left-radius: 5px;
        }

        &:last-child {
          border-top-right-radius: 5px;
        }
      }

      tr:last-child td {
        border-bottom: 2px solid #BFC4C8;

        &:first-child {
          border-bottom-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
