.info-page {
  padding: 40px;
}

.info-page-center {
  max-width: 720px;
  margin: 72px auto;
  color: #fff;
}

.info-page h1 {
  font-weight: 900;
  font-size: 64px;
  font-family: 'local-husans', Avenir, sans-serif;
  margin: 0;
}

.info-page p {
  color: #ddd;
  line-height: 1.8;
}

.info-page .button-manual {
  display: inline-block;
  text-decoration: none;
  margin-top: 64px;
}
