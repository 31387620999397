.quiz {
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.quiz .question {
  font-size: 32px;
  margin-bottom: 96px;
}

.quiz input {
  border: 0;
  -webkit-appearance: none;
  height: 100px;
  font-size: 72px;
  background: transparent;
  text-align: center;
  max-width: 640px;
  border-bottom: 3px solid #fff;
  color: #fff;
}

.quiz .progress {
  font-family: 'local-husans', sans-serif;
  font-size: 20px;
  margin-top: 24px;
  margin-bottom: 54px;
}

.quiz-graph {
  padding: 32px;
  padding-bottom: 20px;
  background: #fff;
  border-radius: 10px;
  width: 80vw;
  max-width: 800px;
  height: 320px;
  color: #96A5A5;
  font-family: 'local-husans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.quiz-graph > div {
  flex-basis: 33%;
}

.quiz-graph .quiz-legend {
  text-align: right;
  margin-top: 64px;
  display: flex;
  color: #BFC4C8;
  flex: 1;
  flex-basis: 48px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}

.quiz-legend div:after {
  position: absolute;
  left: 84px;
  right: 32px;
  content: '';
  border-bottom: 1px solid #d8d8d8;
  opacity: 0.2;
  height: 10px;
}

.quiz-graph .quiz-legend div {
  height: 24px;
}

.quiz-legend .line:after {
  border-bottom: 2px solid #25BB47;
  opacity: 1;
}

.quiz-res {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
  cursor: pointer;
}

.quiz-res h3 {
  font-weight: normal;
  font-size: 22px;
  margin: 0;
  height: 90px;
  background: no-repeat 50% 32px url('../assets/icon-correct.svg');
  background-size: 24px;
}

.quiz-res.fail h3 {
background-image: url('../assets/icon-incorrect.svg');
}

.quiz-res .bars {
  max-height: 244px;
  min-height: 244px;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 20;
}

.quiz-res .bar {
  width: 50px;
  background-color: #4BDE7D;
  background-image: linear-gradient(180deg, #4BDE7D 0%, #25BB47 100%);
  border-radius: 5px;
  min-height: 50px;
}

.quiz-res .bar div {
  width: 50px;
  height: 50px;
  background: no-repeat transparent url('../assets/icon-info.svg') center;
}

.quiz-res.fail .bar {
  background-image: linear-gradient(180deg, #C7D1D1 0%, #96A5A5 100%);
  transition: background-image 0.2s ease;
}

.quiz-res.fail .bar:hover {
  background-image: linear-gradient(180deg, #2d75d2 0%, #2761b2 100%);
}

.quiz h1 {
  font-weight: normal;
  font-size: 40px;
  margin: 0;
  line-height: 1.5;
}

.quiz h2 {
  margin: 0;
  line-height: 1.5;
  margin-bottom: 32px;
  font-weight: normal;
  font-size: 24px;
  color: #2d75d2;
  font-family: 'local-husans', sans-serif;
}

button.try-again {
  background-color: #2d75d2;
  margin-top: 64px;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-inner {
  width: 720px;
  border-radius: 10px;
  background: #fff;
  color: #000;
  padding: 36px;
  position: relative;
}

.modal-inner .times {
  position: absolute;
  top: -24px;
  right: -24px;
  color: #444;
  font-weight: bolder;
  background: #ddd;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.modal-inner h2 {
  font-family: 'avenir-heavy', sans-serif;
  color: #96A5A5;
  font-size: 20px;
}

.modal-inner table {
  width: 100%;
  text-align: left;
}

.modal-inner table th {
  font-family: 'local-husans', sans-serif;
  font-weight: normal;

  color: #96A5A5;
}

.modal-inner table td {
  padding: 4px 0;
}

.modal-inner table td.correct {
  color: #4BDE7D;
}

.modal-inner table td.wrong {
  color: #BB2525;
}
