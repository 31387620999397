.login-area {
  flex: 1;
  display: flex;
  background: transparent url('../assets/background.jpg') no-repeat 0 70%;
  background-size: cover;

  .inner {
    margin-left: 50%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-inner {
      max-width: 450px;
      flex: 1;
    }

    .login-container {
      text-align: center;
      background: #fff;
      border-radius: 10px;
      padding: 40px;

      p {
        color: #96A5A5;
        margin: 40px 0;
      }

      input {
        display: block;
        height: 72px;
        width: 100%;
        border: 0;
        border-bottom: 3px solid #EDF1F1;
        font-size: 50px;
        text-align: center;
        margin-bottom: 48px;

        &::placeholder {
          color: #EDF1F1;
        }
      }

      button {
        padding-left: 54px;
        background: #25BB47 url('../assets/icon-lock.svg') no-repeat 22px 50%;
      }
    }
  }
}

.login-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;

  a {
    color: #fff;
    text-decoration: none;
    padding: 16px 0;
  }

  a:first-child {
    padding-right: 24px;
    background: transparent url('../assets/icon-arrow.svg') no-repeat 95% 52%;
    background-size: 12px;
    transition: background-position ease 0.2s;
  }

  a:first-child:hover {
    background-position: 98% 52%;
  }
}

.login-loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 80px;
  height: 80px;
  position: relative;
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 80%;
  background-color: #fff;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
