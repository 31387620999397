.mobile-compat-overlay {
  display: none;
}

@media (max-width: 1000px) {
  .mobile-compat-overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #272727;
    color: #fff;
    text-align: center;
    padding: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 7000;
    line-height: 1.7;

    img {
      margin-bottom: 48px;
      max-height: 40px;
      width: 50%;
    }
  }
}
