@font-face {
    font-family: 'local-husans';
    src: local('local-husans'), url('./assets/fonts/husans-normal.woff2') format('woff2'), url('./assets/fonts/husans-normal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'local-avenir';
    src: local('local-avenir'), url('./assets/fonts/avenir-heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
