html,
body,
#root {
  margin: 0;
  padding: 0;
  background: #272727;
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  user-select: none;
  max-width: 100%;
  overflow: hidden;

  font-family: "local-avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
.textarea--ghost {
  padding: 0;
  line-height: 1.1;
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-family: "local-avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.textarea--ghost {
  opacity: 0;
  display: block;
  white-space: pre-wrap;
  padding: 0 !important;
  line-height: 1.1;
  word-wrap: break-word;
  visibility: hidden;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: -1000px;
  pointer-events: none;
}

button,
a.button-button {
  border: 0;
  color: #fff;
  font-weight: normal;
  font-family: 'local-husans', sans-serif;
  outline: none;
  line-height: 44px;
  height: 42px;
  overflow: hidden;
  cursor: pointer;
  padding: 0 22px;
  background-color: #25BB47;
  font-size: 22px;
  border-radius: 3px;
}

button:active,
a.button-button:active {
  background-color: #1fa03c;
}

main {
  padding: 20px 26px;
  flex: 1;
}

.placeholder {
  font-family: 'local-husans', sans-serif;
  display: block;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 84vh;
  color: #fff;
  font-size: 30px;
  padding: 40px;
  font-weight: normal;
}
