.letter {
  transform: rotate(-2deg);
  transition: transform 0.1s ease;
  border: 2px solid #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.20);
  border-radius: 5px;
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  font-size: 44px;
  color: #fff;
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  cursor: move;

  &.disabled {
    cursor: default;
  }

  &.present {
    background: #25BB47;
  }

  &.past {
    background: #0874d9;
  }

  &.participle {
    background: #d50000;
  }
}

.cards {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 440px;
  min-width: 330px;
  margin-right: 40px;

  .card {
    border-radius: 5px;
    margin-bottom: 10px;
    flex: 1;
    position: relative;
    height: 33%;
    overflow: hidden;

    &.card-disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.present {
      background: rgba(37, 187, 71, 0.7);
    }

    &.past {
      background: rgba(8, 116, 217, 0.7);
    }

    &.participle {
      background: rgba(229, 57, 53, 0.7);
      margin-bottom: 0;

      .inner {
        left: 120px;
      }
    }

    .front {
      .card-info {
        position: absolute;
        top: 17px;
        right: 14px;
        display: inline-block;
        width: 32px;
        height: 32px;
        text-indent: -100px;
        overflow: hidden;
        cursor: pointer;
        opacity: 0.7;
        z-index: 1000;
        background: transparent no-repeat center url('../assets/icon-info.svg');

        &:hover {
          opacity: 1;
        }
      }

      h3 {
        padding-right: 28px;
      }
    }

    .back {
      display: none;
      background: #fff;
      position: relative;
      height: 100%;
      flex: 1;

      .card-close {
        position: absolute;
        top: 17px;
        right: 14px;
        display: inline-block;
        width: 32px;
        height: 32px;
        text-indent: -100px;
        overflow: hidden;
        cursor: pointer;
        background: transparent no-repeat center url('../assets/icon-close.svg');
      }

      h3 {
        left: 20px;
        right: auto;
      }

      .green {
        color: #25BB47;
      }

      .blue {
        color: #0874d9;
      }

      .red {
        color: #d50000;
      }

      .back-content {
        height: 100%;
        flex: 1;
        overflow: auto;
      }

      .back-content-inner {
        padding: 20px;
        padding-top: 60px;
        padding-bottom: 4px;
      }

      p {
        font-size: 14px;
        font-weight: bold;
        color: #96A5A5;
        margin: 0;
        padding-right: 20px;
      }

      strong {
        color: #0A7FDE;
        font-weight: inherit;
      }

      ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      ul {
        margin-top: 16px;
      }

      ul.columns {
        max-height: 130px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        li {
          max-width: 50%;
          padding-right: 10px;
          box-sizing: border-box;
        }
      }

      .rule {
        display: flex;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        & > div {
          flex: 1;
          max-width: 70%;

          &:first-child {
            padding-left: 20px;
            opacity: 0.7;
            font-size: 14px;
            max-width: 30%;
            padding-right: 10px;
            background: no-repeat 0 3px url('../assets/icon-mini-arrow.svg');
          }

          &:last-child {
            p {
              font-weight: normal;
              margin-bottom: 7px;
              margin-right: 0;
              padding-right: 0;
            }
            .ex {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 4px;
              color: #000;
              letter-spacing: 0.5px;

              del {
                text-decoration: none;
                color: #d50000;
              }
            }
          }
        }
      }
    }

    &.card.open {
      .back {
        display: block;
      }

      .front {
        display: none;
      }
    }

    h3 {
      font-size: 24px;
      margin: 0;
      padding: 0;
      top: 20px;
      right: 20px;
      position: absolute;
      color: #fff;
      font-weight: normal;
      font-family: 'local-husans', sans-serif;
    }

    .inner {
      position: absolute;
      left: 24px;
      right: 24px;
      top: 48px;
      bottom: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      .letter:nth-child(2) {
        margin-top: 44px;
        transform: rotate(1deg);
        margin-left: 30px;
      }

      .letter:nth-child(3) {
        margin-top: 3px;
        transform: rotate(2deg);
      }
    }

    .prefixes {
      position: absolute;
      left: 10px;
      top: 10px;
      bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .letter {
        transform: rotate(0);
        background: #303233;
        font-size: 30px;
        height: 56px;
        width: 56px;
        min-width: 56px;
        min-height: 56px;
        margin: 5px;

        &.ver, &.be {
          opacity: 0.45;
          pointer-events: none;
        }
      }
    }
  }
}

.legend {
  position: fixed;
  right: 20px;
  bottom: 20px;
  margin: 0;
  opacity: 0.9;

  li {
    float: left;
    margin: 0;
    margin-left: 40px;
    font-size: 15px;
    color: #fff;

    .green {
      color: #25BB47;
    }

    .blue {
      color: #0874d9;
    }

    .red {
      color: #d50000;
    }
  }
}

.work-area {
  margin: 0 auto;
  display: flex;
  flex: 1;
  position: relative;

  .work-area-inner {
    max-width: 780px;
    min-width: 630px;
    margin: 0 auto;
    display: flex;
    flex: 3;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    padding: 64px;

    .textarea {
      -webkit-appearance: none;
      background: transparent;
      border: 0;
      outline: 0;
      resize: none;
    }
  }
}

.sentence {
  color: #fff;
  font-size: 36px;

  .textarea {
    width: 100%;
    color: #fff;
    font-size: 36px;
    overflow: hidden;
  }

  &.editable:after {
    display: block;
    width: 200px;
    height: 0;
    content: '';
    margin-top: 6px;
    border-bottom: 2px solid #edf1f1;
  }
}

.answer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 64px;
  color: #fff;

  .line {
    display: flex;
  }

  .letters-target {
    display: flex;
    overflow: hidden;
    margin: -20px;
    margin-left: -5px;
    flex-wrap: nowrap;
    height: 147px;
    min-width: 120px;
    position: relative;

    &.prefix-target {
      margin-right: 10px;
    }

    .letter {
      transform: rotate(0);
      font-size: 64px;
      width: 96px;
      height: 96px;
      min-width: 96px;
      min-height: 96px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -64px;
      border: 2px dashed rgba(255,255,255,0.7);
      border-radius: 5px;
      width: 96px;
      height: 96px;
      min-width: 96px;
      min-height: 96px;
      margin-right: 0;
      pointer-events: none;
    }
  }

  .textarea {
    overflow: hidden;
    max-width: 450px;
    font-size: 70px;
    height: 80px;
    text-align: left;
    color: #fff;
    min-width: 80px;
  }

  .verb-root.editable:after {
    display: block;
    height: 0;
    content: '';
    margin-top: 6px;
    margin-right: 60px;
    border-bottom: 2px solid #edf1f1;
  }

  .verb-root {
    margin-right: 16px;

    &.editable {
      margin-right: -40px;
    }
  }
}

.feedback {
  text-align: center;
  padding-bottom: 60px;
  padding-top: 40px;
  color: #fff;

  .feedback-next {
    color: #fff;
    text-decoration: none;
    font-family: 'local-husans', sans-serif;
    font-size: 22px;
    font-weight: normal;
    line-height: 34px;
    display: inline-block;
    padding-right: 24px;
    background: url('../assets/icon-arrow.svg') no-repeat right;

    span {
      line-height: 34px;
      display: inline-block;
      margin-right: 10px;
      padding-left: 32px;
      background: no-repeat left;
      background-size: 26px;
    }

    &.correct span {
      color: #25BB47;
      background-image: url('../assets/icon-correct.svg');
    }

    &.incorrect span {
      color: #EA4D36;
      background-image: url('../assets/icon-incorrect.svg');
    }
  }

  p {
    font-size: 17px;
    opacity: 0.8;
    margin-top: 6px;
  }
}

.pen-mode {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: url('../assets/icon-pen.svg') no-repeat 0 100%;
  background-size: contain;
  text-indent: -1000px;
  white-space: nowrap;
  cursor: pointer;
  z-index: 300;
}

.pen-mode.active {
  background-image: url('../assets/icon-mouse.svg');
}

.root-mode {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #fff;
  white-space: nowrap;
  cursor: pointer;
  color: #000;
  height: 32px;
  line-height: 34px;
  font-size: 16px;
  padding: 0 10px;
  z-index: 300;
}

.work-area button.wipe {
  background: #fff;
  color: #000;
  height: 32px;
  line-height: 34px;
  padding: 0 10px;
  font-size: 16px;
  position: absolute;
  left: 50px;
  bottom: 0;
  cursor: pointer;
  z-index: 300;
}

.work-area canvas {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 100;
}

.work-area.pen {
  textarea,
  textarea::placeholder {
    color: transparent;
    pointer-events: none;
    min-width: 350px !important;
  }

  .letters-target {
    position: relative;
    z-index: 300;
  }

  .letter-slot {
    pointer-events: none;
  }
}
