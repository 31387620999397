nav {
  height: 44px;
  width: 100%;
  display: flex;
  background: #ebebeb;
  flex-direction: row;
  font-family: 'local-husans', Avenir, sans-serif;
}

nav .tabs,
nav .tabs li {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav .tabs {
  flex: 1;
  display: flex;
}

nav .tabs li {
  flex: 1;
  flex-basis: 14.285714286%;
  text-align: center;
  height: 44px;
}

nav .tabs li a {
  color: #9e9e9e;
  text-decoration: none;
  font-size: 20px;
  line-height: 46px;
  height: 44px;
  overflow: hidden;
  display: block;
  position: relative;
  z-index: 1;
}

nav .tabs li a:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent no-repeat url('../assets/tab.svg') center;
  background-size: 101% 100%;
  opacity: 0.9;
  z-index: 0;
}

nav .tabs li a.active:before {
  opacity: 1;
}

nav .tabs li a span {
  position: relative;
  z-index: 10;
}

nav .tabs li a.active {
  color: #fff;
}

nav .tabs li.logo a span {
  background: transparent no-repeat url('../assets/logo.svg') center;
  background-size: auto 16px;
  text-indent: -1000px;
  overflow: hidden;
  width: 100%;
  display: block;
  height: 44px;
}
